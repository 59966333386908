html,
body,
div,
span,
applet,
object,
iframe,

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,

a,
abbr,
acronym,
address,
big,
cite,
code,

del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,

small,
strike,
strong,
sub,
sup,
tt,
var,

b,
u,
i,
center,

dl,
dt,
dd,
ol,
ul,
li,

fieldset,
form,
label,
legend,

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,

article,
aside,
canvas,
details,
embed,

figure,
figcaption,
footer,
header,
hgroup,

menu,
nav,
output,
ruby,
section,
summary,

time,
mark,
audio,
video {

  margin: 0;

  padding: 0;

  border: 0;

  font-size: 100%;

  font: inherit;

  vertical-align: baseline;

}



/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,

footer,
header,
hgroup,
menu,
nav,
section {

  display: block;

}



body {

  line-height: 1;

}



ol,
ul {

  list-style: none;

}



blockquote,
q {

  quotes: none;

}



blockquote:before,
blockquote:after,

q:before,
q:after {

  content: '';

  content: none;

}



table {

  border-collapse: collapse;

  border-spacing: 0;

}

a {
  text-decoration: none;
}

:root {
  --primary-color: #5482FD;
  --text-color: #1E2127;
  --el-color-primary: var(--primary-color)
}

body,
html {
  width: 100%;
  font-size: 14px;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

.center {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
}

p {
  color: var(--text-color);
}

button {
  outline: none;
  border: transparent;
  border-radius: 6px;
  cursor: pointer;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mb40 {
  margin-bottom: 40px;
}